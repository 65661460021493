import React from "react";
import ganeshkhati from "../assets/images/ganeshkhati.jpg";
// import suryakhati from "../assets/images/surya bahadur khati.jpg";
const Messages = () => {
	return (
		<div className='messages'>
			{/* <div className='message chairman'>
				<img src={suryakhati} alt='message from chairman' />
				<h1>message from chairman</h1>
				<p>
					म हाम्रो वेबसाइट भ्रमण गर्नुभएकोमा धन्यवाद दिन चाहन्छु। मलाई आशा छ कि
					वेबसाइटले हाम्रो कम्पनी प्रोफाइल गर्न सक्षम भएको छ र हामी आशा गर्दछौं
					कि हाम्रा उत्पादनहरू र सेवाहरू तपाईंको रुचिको हुन सक्छन्।
				</p>
				<p>
					हामी उच्च-गुणस्तरका सेवाहरू र उत्पादनहरू, समय र बजेट भित्र उपलब्ध
					गराउन प्रतिबद्ध छौं, र यस कारणले गर्दा, हाम्रो ग्राहक आधार बढ्दै गएको
					छ। हामीसँग सीप, स्रोत र विशेषज्ञता भएकोमा हामी गर्व गर्छौं, र सबैभन्दा
					महत्त्वपूर्ण कुरा, उच्चतम मापदण्डहरू प्रदान गर्ने क्षमता र इच्छाशक्ति,
					र समयमै महत्त्वपूर्ण रूपमा यो भविष्यको परिकल्पनामा हुन सक्छ, चम्प्वती
					को अनुभव मात्र स्रोत हुन सक्दैन। दिगो विकास र सफलताको लागि। हामी
					चुनौतीको सामना गर्न सक्छौं र सबैभन्दा उन्नत व्यवस्थापन नीतिहरू र
					प्रक्रियाहरू लागू गरेर हाम्रा ग्राहकहरूको सन्तुष्टिको लागि हाम्रो
					प्रदर्शनमा उत्कृष्ट बन्न सक्छौं।
				</p>
			</div> */}
			<div className='message manager'>
				<img src={ganeshkhati} alt='message from chairman' />
				<h1>message from manager</h1>
				<p>
					चम्पावती को-अपरेटिभमा १३ वर्षभन्दा बढी समय बिताएपछि, प्रमुख प्रबन्धक
					हैसियतमा यो सन्देश लेख्न पाउँदा म अत्यन्तै सौभाग्य महसुस गर्दैछु।
				</p>
				<br />
				<p>
					अगाडि हेर्दै, हामी हाम्रो सहकारीको बलमा निर्माण जारी राख्दै हाम्रो
					विकास रणनीतिको कार्यान्वयनलाई तीव्रता दिनमा केन्द्रित छौं - हाम्रा
					सदस्यहरूलाई ज्ञान साझा गर्न र नवीनता सिर्जना गर्न र हामी काम गर्ने र
					बस्ने समुदायहरूमा सकारात्मक परिवर्तन ल्याउनमा हाम्रो टोली अधिक समर्पित
					छ र सबै आउटरिच क्षेत्रहरूमा परिवर्तन ल्याउन पर्याप्त छ। यस सहकारीको थप
					उपलब्धि हासिल गर्नको लागि सञ्चालक समिति, सरोकारवालाहरू, सदस्यवर्ग,
					व्यवस्थापन टोली, शाखा समन्वय टोली र सम्पूर्ण परिवारप्रति उहाँहरूको साथ
					र समर्पणको लागि म धेरै धेरै आभारी छु।
				</p>
			</div>
		</div>
	);
};

export default Messages;
