import React from "react";

const OrganisationalStructure = () => {
	return (
		<div>
			<h1>Organisational Structure here</h1>
		</div>
	);
};

export default OrganisationalStructure;
