import React from "react";

const SMSBanking = () => {
	return (
		<div>
			<h1>Description of SMS Banking here</h1>
		</div>
	);
};

export default SMSBanking;
