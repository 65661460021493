import React, { useState } from "react";
import { Pie } from "react-chartjs-2";
import { FaCalculator, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";

const EMICalculator = () => {
	const [loanAmount, setLoanAmount] = useState(100000);
	const [interestRate, setInterestRate] = useState(8.03);
	const [duration, setDuration] = useState(36);
	// let emi = 3135.02;
	// let interest = 12861;
	// let totalAmount = 112861;

	const intr = interestRate / 1200;
	const emi = duration
		? ((loanAmount * intr) / (1 - Math.pow(1 / (1 + intr), duration))).toFixed(
				2
		  )
		: 0;

	const totalAmount = emi * duration;
	const interest = (totalAmount - loanAmount).toFixed(2);

	return (
		<div className='emicalculator'>
			<div className='content'>
				<h2>Emi Calculator</h2>
				<p>
					{" "}
					<FaQuoteLeft />
					Know you EMI ??
					<FaQuoteRight />
				</p>
				<div className='fields'>
					<div className='cal-area'>
						<div className='inputfield'>
							<label htmlFor='loanamount'>Loan Amount (Rs.)</label>
							<input
								type='number'
								className='form-control'
								name='loanamount'
								id='loanamount'
								value={loanAmount}
								onChange={(e) => setLoanAmount(e.target.value)}
							/>
						</div>
						<div className='inputfield'>
							<label htmlFor='interestrate'>Interest Rate (%)</label>
							<input
								type='number'
								className='form-control'
								name='interestrate'
								id='interestrate'
								value={interestRate}
								onChange={(e) => setInterestRate(e.target.value)}
							/>
						</div>
						<div className='inputfield'>
							<label htmlFor='term'>Term (Months)</label>
							<input
								type='number'
								className='form-control'
								name='term'
								id='term'
								value={duration}
								onChange={(e) => setDuration(e.target.value)}
							/>
						</div>
						<div className='calculate'>
							<FaCalculator className='icon' />
							<button>Result</button>
						</div>

						<div className='inputfield'>
							<label htmlFor='emi'>Monthly Payment(EMI) (Rs.)</label>
							<input
								type='number'
								className='form-control'
								name='emi'
								id='emi'
								value={emi}
							/>
						</div>
						<div className='inputfield'>
							<label htmlFor='emi'>Total Interest(Rs.)</label>
							<input
								type='number'
								className='form-control'
								name='emi'
								id='emi'
								value={interest}
							/>
						</div>
						<div className='inputfield'>
							<label htmlFor='emi'>Total Payment(Rs.)</label>
							<input
								type='number'
								className='form-control'
								name='emi'
								id='emi'
								value={totalAmount}
							/>
						</div>
					</div>

					<div className='pie'>
						<Pie
							data={{
								labels: ["total Interest", "Total Amount"],
								datasets: [
									{
										data: [interest, totalAmount],
										backgroundColor: ["red", "blue"],
									},
								],
							}}
							width={200}
							// height={200}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EMICalculator;
