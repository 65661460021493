import React from "react";
import News from "./news";
import Slider from "./slider";
const Main = () => {
	return (
		<div className='main'>
			<News />
			<Slider />
		</div>
	);
};

export default Main;
