import React, { useState, useEffect } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { SliderImage } from "./data";

const Slider = () => {
	const [sliderImage, setImage] = useState(SliderImage);
	const [index, setIndex] = useState(0);

	const getSliderImage = async () => {
		const res = await fetch("https://champawotisaccos.com.np/api/sliderimage/");
		const data = await res.json();
		return data;
	};
	useEffect(() => {
		getSliderImage()
			.then((data) => {
				setImage(data);
				console.log(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		const lastIndex = sliderImage.length - 1;
		if (index < 0) {
			setIndex(lastIndex);
		}
		if (index > sliderImage.length - 1) {
			setIndex(0);
		}
	}, [index, sliderImage]);

	useEffect(() => {
		const slider = setInterval(() => {
			setIndex(index + 1);
		}, 6000);

		return () => clearInterval(slider);
	}, [index]);
	return (
		<div className='slider'>
			<div className='images'>
				{sliderImage.map((item, personIndex) => {
					let position = "nextSlide";
					if (personIndex === index) {
						position = "activeSlide";
					}
					if (
						personIndex === index - 1 ||
						(index === 0 && personIndex === sliderImage.length - 1)
					) {
						position = "lastSlide";
					}
					return (
						<article key={item.id} className={position}>
							<img src={item.image} alt='sliderimg' className='my-image' />
						</article>
					);
				})}

				<div className='icon'>
					<FaChevronLeft className='prev' onClick={() => setIndex(index - 1)} />

					<FaChevronRight
						className='next'
						onClick={() => setIndex(index + 1)}
					/>
				</div>
			</div>
		</div>
	);
};

export default Slider;
