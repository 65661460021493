import React from "react";
const Objective = () => {
	return (
		<section className='eventspage-wrapper'>
			<h2>Objective</h2>
			<div className='eventspage vision'>
				<article
					className='vision-content'
					style={{ textAlign: "left", margin: "3rem auto" }}>
					<h3>वार्षिक उद्देश्यहरु ः</h3>
					<p>
						१) गुणस्तरिय सेवा वितार गर्ने ा
						<br />
						२) बजार विस्तार तथा प्रबर्न्धन गर्ने ।
						<br />
						३) सदस्यहरुको सन्तुष्टि सर्वेक्षण गर्ने ।
						<br />
						४) जगेडा कोषलाई वृद्धि गर्दै जार्ने ।
						<br />
						५)आधुनिक प्रबिधिको उच्चतम प्रयोग गर्ने ।
						<br />
						६) सहकारि ऐन, नियमको पूर्ण पालन गर्ने ।
						<br />
						७) संचालक तथा कर्मचारिको क्षमता विकास गर्ने ।
						<br />
						८) बचत तथटा ऋणको प्रकारलाई समय अनुसार परिर्माजन गर्ने ।
					</p>
				</article>
			</div>
		</section>
	);
};

export default Objective;
