import React from "react";

const MobileBanking = () => {
	return (
		<div>
			<h1>Description of mobile banking here</h1>
		</div>
	);
};

export default MobileBanking;
