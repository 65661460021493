import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
const Vision = () => {
	return (
		<section className='eventspage-wrapper'>
			<h2>Vision</h2>
			<div className='eventspage vision'>
				<article className='vision-content'>
					<p>
						<FaQuoteLeft className='icon' /> सवल, व्यवसायिक र गुणस्तरिय
						सेवाकोलागि चम्पावति साकोस । <FaQuoteRight className='icon' />
					</p>
				</article>
			</div>
		</section>
	);
};

export default Vision;
