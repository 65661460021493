import React from "react";
import { RiDoubleQuotesR, RiDoubleQuotesL } from "react-icons/ri";
import { PartnersData } from "./data";
import { FaHandshake } from "react-icons/fa";

const Partners = () => {
	return (
		<section className='partner-wrapper'>
			<div className='partners'>
				<h2>REMITTANCE PARTNERS</h2>
				<p className='slogan'>
					<RiDoubleQuotesL /> सवल, व्यवसायिक र गुणस्तरिय सेवाकोलागि चम्पावति
					साकोस ।
					<RiDoubleQuotesR />
				</p>
				<div className='collect'>
					<div className='underline'>
						<FaHandshake className='welcomeicon' />
					</div>
					<hr />
				</div>

				<div className='partner'>
					{PartnersData.map((item) => {
						return (
							<article key={item.id}>
								<img src={item.img} alt='partner' />
							</article>
						);
					})}
				</div>
			</div>
		</section>
	);
};

export default Partners;
