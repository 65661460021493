import React from "react";
import logo from "../assets/images/logo.png";
import { BsClockHistory, BsTelephone } from "react-icons/bs";
import { openClose } from "./openClose";
import { Link } from "react-router-dom";

const Header = () => {
  const isOpen = openClose();
  return (
    <div className="header">
      <div className="logo">
        <Link to="/">
          {" "}
          <img src={logo} alt="champawatiLogo" />
        </Link>
        <h2 className="title">चम्पावती </h2>
        <div className="title_text">
          <span className="nepali">बचत तथा ऋण सहकारी संस्था </span>
          <hr className="underline" />
          <span className="english">Champawati saving & credit co. ltd.</span>
        </div>
      </div>

      <div className="connect">
        <div className="connect_link">
          <BsClockHistory className="icon" />
          <div className="text">
            <p className="active">Sun-Fri:10am-5pm</p>
            {isOpen ? (
              <p className="active isopen-open">(सार्वजनिक बिदा बाहेक )</p>
            ) : (
              <p className="disabled isopen-closed">(सार्वजनिक बिदा बाहेक )</p>
            )}
          </div>
        </div>
        <div className="connect_link">
          <BsTelephone className="icon" />
          <div className="text">
            <p className="active">01-5906224 , 01-5916224</p>
            <p className="disabled">champawatisaving@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
