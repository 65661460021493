import React, { useState, useEffect } from "react";
import { EventsData } from "../components/data";
import { Link } from "react-router-dom";
import { AiOutlineBars } from "react-icons/ai";
const EventsPage = () => {
	const [eventsData, setEventsData] = useState(EventsData);
	const getEvents = async () => {
		const res = await fetch("https://champawotisaccos.com.np/api/events/");
		const data = await res.json();
		return data;
	};
	useEffect(() => {
		getEvents()
			.then((data) => {
				setEventsData(data.reverse());
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<section className='eventspage-wrapper'>
			<h2>News/Events</h2>
			<div className='eventspage'>
				<ul>
					{eventsData.map((event) => {
						return (
							<li key={event.id}>
								<img src={event.image} alt='eventimage' />
								<div className='content'>
									<h3 className='title'>{event.title}</h3>
									<p className='date'>
										<span>Published Date: </span>
										{event.date}
									</p>

									<p>{`${event.description.substring(0, 100)}...`}</p>

									<button className='btn btn-success'>
										<Link to={`/singleevent/${event.id}`}>
											<AiOutlineBars className='icon' />
											KNOW MORE
										</Link>
									</button>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</section>
	);
};

export default EventsPage;
