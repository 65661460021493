import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Data } from "./data";
import { FaFileDownload } from "react-icons/fa";
import { AiFillCaretDown } from "react-icons/ai";
import { MdDoubleArrow } from "react-icons/md";

const Navbar = () => {
	const [active, setActive] = useState(null);
	const [downloadFile, setDownloadFile] = useState(null);
	const [showLinks, setShowLinks] = useState(true);
	const linksRef = useRef(null);
	const getEvents = async () => {
		const res = await fetch(
			"https://champawotisaccos.com.np/api/downloadfile/"
		);
		const data = await res.json();
		return data;
	};
	useEffect(() => {
		getEvents()
			.then((data) => {
				setDownloadFile(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	useEffect(() => {
		if (showLinks) {
			linksRef.current.style.transform = "translateX(0%)";
		} else {
			linksRef.current.style.transform = "translateX(-110%)";
		}
	}, [showLinks]);

	return (
		<div className='navbar'>
			<div className='navbar_component'>
				<ul className='menu' ref={linksRef}>
					{Data.map((navItem) => {
						return (
							<li key={navItem.id}>
								<Link
									to={navItem.link}
									onClick={() => setActive(navItem.id)}
									className={`${
										navItem.id === (active || 1) && "active_link"
									}`}>
									<span className='icon'>{navItem.icon}</span>
									{navItem.text}
									<span className='icon-last'>
										{" "}
										{navItem.icon1 && navItem.icon1}
									</span>
									{navItem.links && (
										<ul className='sublinks'>
											{navItem.links.map((item, index) => {
												return (
													<li key={index} className='sublinks_list'>
														<Link to={item.url} className='sublinks_item'>
															{item.label}
														</Link>
													</li>
												);
											})}
										</ul>
									)}
								</Link>
							</li>
						);
					})}
				</ul>

				<ul className='downloads'>
					<li>
						<button className='download'>
							{" "}
							<FaFileDownload className='icon' />
							DOWNLOAD
							<AiFillCaretDown />
							<ul className='sublinks'>
								{downloadFile &&
									downloadFile.map((file) => {
										return (
											<li key={file.id} className='sublinks_list'>
												<a href={file.file} className='sublinks_item' download>
													{file.title}
												</a>
											</li>
										);
									})}
							</ul>
						</button>
					</li>
				</ul>
				<div className='bars' onClick={() => setShowLinks(!showLinks)}>
					<MdDoubleArrow />{" "}
				</div>
			</div>
		</div>
	);
};

export default Navbar;
