import React from "react";
import Iframe from "../components/iframe";
import { FaLocationArrow } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { SiGmail } from "react-icons/si";
import {
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaFacebook,
  FaHandshake,
} from "react-icons/fa";
const Contact = () => {
  return (
    <section className="contact-wrapper">
      <div className="contact">
        <div className="header">
          <h4 className="title"> Contact Us</h4>

          <div className="collect">
            <div className="underline">
              <FaHandshake className="welcomeicon" />
            </div>
            <hr />
          </div>
        </div>
        <div className="main">
          <div className="content">
            <div className="headoffice">
              <h3>MAIN OFFICE</h3>
              <p>
                <FaLocationArrow className="icon_" /> Kathmandu 16,
                Machhapokhari
              </p>
              <p>
                <a href="mailto:mail.champawotisaccos.com.np">
                  <SiGmail className="icon_" />
                  champawatisaving@gmail.com
                </a>
              </p>
              <p>
                <MdCall className="icon_" /> 01-5916224, 01-5906224
              </p>
            </div>

            <div className="social-links">
              <a
                href="https://www.facebook.com/champawati.saving"
                target="blank"
              >
                {" "}
                <FaFacebook className="icon" />
              </a>
              <a href="https://www.instagram.com" target="blank">
                <FaInstagram className="icon" />
              </a>
              <a href="https://www.linkedin.com" target="blank">
                <FaLinkedin className="icon" />
              </a>
              <a href="https://www.twitter.com" target="blank">
                {" "}
                <FaTwitter className="icon" />
              </a>
            </div>
          </div>
          <div className="map">
            <Iframe />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
