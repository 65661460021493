import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { EventsData } from "../components/data";

const SigleEvents = () => {
	const { id } = useParams();
	const eventID = Number(id);
	const [eventsData, setEventsData] = useState(EventsData[0]);
	const getEvents = async () => {
		const res = await fetch(
			`https://champawotisaccos.com.np/api/events/${eventID}/`
		);
		const data = await res.json();
		return data;
	};
	useEffect(() => {
		getEvents()
			.then((data) => {
				setEventsData(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	const images = eventsData.images;
	return (
		<section className='eventspage-wrapper'>
			<h2>News/Events</h2>
			<div className='eventspage'>
				<ul>
					<h3 className='title_single'>{eventsData.title}</h3>
					<p className='date_single'>{eventsData.date}</p>
					<p className='description_single'>{eventsData.description}</p>

					<article className='image-grid'>
						<img src={eventsData.image} alt='eventsimage' />
						{images &&
							images.map((image) => {
								return (
									<img src={image.image} key={image.id} alt='eventsimage' />
								);
							})}
					</article>
				</ul>
			</div>
		</section>
	);
};

export default SigleEvents;
