import React from "react";
import binbahadurthapa from "../assets/images/bin bahadur thapa sanchak sadasya .jpg";
import kamalgiri from "../assets/images/kamal giri sadasya.jpg";
import kopilakhati from "../assets/images/kopila khati sadassya.jpg";
import laxmanbhandari from "../assets/images/laxman bhandari sadasya.jpg";
import rudrakarki from "../assets/images/rudra karki lekha samiit sakmayojak.jpg";
import sailendrabikram from "../assets/images/sailendera bikram thapa sachib.jpg";
import santoshgiri from "../assets/images/santosh giri kosadhchya.jpg";
import suryakhati from "../assets/images/surya bahadur khati.jpg";

const BoardMembers = () => {
	return (
		<section className='eventspage-wrapper'>
			<h2>Board Members</h2>
			<div className='eventspage vision'>
				<article className='boardmembers-content'>
					<div className='sanchalak samiti'>
						<h3>संचालक समिति</h3>

						<li>
							<img src={suryakhati} alt='members' />
							<p className='title'>
								<strong>सुर्य बहादुर खाती</strong>
							</p>
							<p className='post'>अध्यक्ष्</p>
						</li>
						<div className='members'>
							<li>
								<img src={""} alt='उपअधक्ष्' />
								<p className='title'>
									<strong>राम प्रसाद भुसाल</strong>
								</p>
								<p className='post'>उपाध्यक्ष्</p>
							</li>
							<li>
								<img src={sailendrabikram} alt=' सचिब' />
								<p className='title'>
									<strong>सैलेन्द्र बिक्रम थापा</strong>
								</p>
								<p className='post'>सचिब</p>
							</li>
							<li>
								<img src={santoshgiri} alt='कष्अधक्ष्' />
								<p className='title'>
									<strong>सन्तोष गिरी </strong>
								</p>
								<p className='post'>कोषध्यक्ष्</p>
							</li>
						</div>
						<div className='members'>
							<li>
								<img src={binbahadurthapa} alt='sadasya' />
								<p className='title'>
									<strong>बिन बहादुर थापा</strong>
								</p>
								<p className='post'>सदस्य </p>
							</li>
							<li>
								<img src={kopilakhati} alt='sadasya' />
								<p className='title'>
									<strong>कोपिला खाती</strong>
								</p>
								<p className='post'>सदस्य</p>
							</li>
							<li>
								<img src={laxmanbhandari} alt='sadasya' />
								<p className='title'>
									<strong>लक्ष्मण् भन्डारी</strong>
								</p>
								<p className='post'>सदस्य</p>
							</li>
						</div>
					</div>
					<div className='lekha samiti'>
						<h3>लेखा समिति</h3>
						<div className='members'>
							<li>
								<img src={rudrakarki} alt='samyojak' />
								<p className='title'>
									<strong>रुद्र कार्की</strong>
								</p>
								<p className='post'>संयोजक</p>
							</li>

							<li>
								<img src={kamalgiri} alt='sadasya' />
								<p className='title'>
									<strong> कमल गिरी </strong>
								</p>
								<p className='post'>सदस्य</p>
							</li>
							<li>
								<img src={""} alt='sadasya' />
								<p className='title'>
									<strong>मदन खाती </strong>
								</p>
								<p className='post'>सदस्य</p>
							</li>
						</div>
					</div>
					<div className='lekha samiti'>
						<h3>ऋण् उपसमिति</h3>
						<div className='members'>
							<li>
								<img src={santoshgiri} alt='samyojak' />
								<p className='title'>
									<strong>सन्तोष् गिरी</strong>
								</p>
								<p className='post'>संयोजक </p>
							</li>

							<li>
								<img src={""} alt='sadasya' />
								<p className='title'>
									<strong>दिननाथ खराल</strong>
								</p>
								<p className='post'>सदस्य</p>
							</li>
							<li>
								<img src={""} alt='sadasya' />
								<p className='title'>
									<strong> बिष्ण् ुप्रसाद धिमिरे</strong>
								</p>
								<p className='post'>सदस्य</p>
							</li>
						</div>
					</div>
				</article>
			</div>
		</section>
	);
};

export default BoardMembers;
