import React from "react";
import { Link } from "react-router-dom";
// import img1 from "../assets/images/img-1.png";
import { RiDoubleQuotesR, RiDoubleQuotesL } from "react-icons/ri";
import { AiOutlineBars } from "react-icons/ai";
import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { ServicesData } from "./data";

const Services = () => {
	return (
		<div className='services'>
			<div className='service'>
				<h2>OUR SERVICES</h2>
				<p className='slogan'>
					<RiDoubleQuotesL /> सवल, व्यवसायिक र गुणस्तरिय सेवाकोलागि चम्पावति
					साकोस ।
					<RiDoubleQuotesR />
				</p>
				<div className='collect'>
					<div className='underline'>
						<MdOutlineMiscellaneousServices className='welcomeicon' />
					</div>
					<hr />
				</div>

				<div className='content'>
					<div className='content_text'>
						{ServicesData.map((service, index) => {
							return (
								<article key={index}>
									<span className='icon_'>{service.img}</span>
									<h4 className='title'> {service.title}</h4>
									<p>{service.description}</p>
									<button className='btn btn-success'>
										<Link to={service.url}>
											<AiOutlineBars className='icon' />
											KNOW MORE
										</Link>
									</button>
								</article>
							);
						})}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Services;
