import React from "react";

const Profile = () => {
	return (
		<section className='eventspage-wrapper'>
			<h2>Our Profile</h2>
			<div className='eventspage profile'>
				<article className='profile-content'>
					<p>
						समाजमा छरिएर रहेका साना–साना पूँजीहरुलाई एककृत गरि सदस्यहरुको आर्थिक
						उन्नतीका साथै सामाजिक उत्तरदायित्वलाई बहन गर्ने उद्देश्यका साथ २०६७
						श्रावण ०७ गते गोंगबु गा.वि.स ०५मा प्रारम्भिक भेला र २०६७ श्रावण १५
						गते दोस्रो भेला गरी श्री ऋषिकेश घिमिरेको अध्यक्षतामा सात सदस्ययिय
						सञ्चालक समिति गठन गरी सहकारी डिभिजन कार्यालय काठमाडौमा चम्पावती बचत
						तथा ऋण सहकारी संस्था लिमिटेडको रुपमा विद्यमान कानुनको अधिनमा रहेर
						संस्था दर्ता गरियो ।
					</p>
					<p>
						सहकारी ऐन २०४८ अनुसार सम्पूर्ण प्रत्रिफ्या पुरा गरी मिती २०६७ भाद्र
						२७ गते दर्ता प्रमाण पत्र प्राप्त गरि २०६७ कार्तिक १९ गते प्रारम्भिक
						साधारण सभा सम्पन्न गरियो । २०६८ साल आश्विन १६गते उपाध्यक्ष गोपाल
						बहादुर खत्रीको अध्यक्षतामा दोस्रो साधारण सभा सम्पन्न गर्नुृका साथै
						श्री सुर्य बहादुर अमगाईको अध्यक्षतामा नौ सदस्यिय समिति गठन गरियो ।
						सोहि साधारण सभा मापफर््त शेयर पुँजी रु २५०००००।– अक्षरुपी पच्चीस लाख
						मात्र पुर्‍याइृ विनियम संशोधन पनि गरियो । तत्त पश्चात २०७२ श्रावण १५
						गते संस्थाको छैठौ वार्षिक साधारण सभा र अधिवेशन मापफर््त श्री याम
						कुमार श्रेष्ठको अध्यक्षतामा सात सदस्यिय सञ्चालक समिति गठन गरियो । र
						२०७५ माघ १० गते संस्थाको नवौ साधारण सभा चौँथो अधिवेशन सम्पन्न गरी
						श्री सुर्य बहादुर खातीको अध्यक्ष्यतामा सात सदस्यिय सञ्चालक समिति गठन
						गरियो । र संस्थाको शेयर पुँजी बढाएर एक करोड बनाउने निर्णय गरियो ।
					</p>
					<p>
						संस्था स्थापना पश्चातको केहि वर्षमा समाजमा सहकारी सम्बन्धि ज्ञानको
						अभाव लगायत विभिन्न कारणले संस्थाको उल्लेख्य विकास हुन सकेको थिएन ।
						२०७२ सालको भुकम्पको कारण संस्थाको ऋण लगानी पिर्फ्तामा जटिल समस्या र
						बचत पिर्फ्तामा समस्यामा रहेको कारण र संस्था बलियो नबनिसकेकोले
						संस्थालाई ठुलो असर पुर्‍याउन पुग्यो । त्यसपछि संस्थाको छैठो वार्षिक
						साधारण सभाबाट गठित समितिको कार्यकालमा पुराना समस्याहरुलाई आशिंक
						समाधान गरी संस्थालाई गति दिने काम गरियो ।
					</p>
					<p>
						साथै संस्थाको दऔँ साधारण सभा तथा पाँचौ अधिवेशन २०७८ पौस २७ गते बाट
						पुन दोस्रो कार्यकालको लागि श्री सुर्य बहादुर खातीको अध्यक्ष्यतामा
						सात सदस्यिय सञ्चालक समिति , लेखा सुरिवेक्षण समिति, गठन गरी सस्थालाई
						थप नयाँ ढङ्गले सञ्चालन गरी संस्थालाई हालको उचाई सम्म ल्याउन सपफ्ल
						भएका छौँ । संस्थाको विकास केहि हदसम्म भएतापनि संस्था पुग्नुपर्ने
						गन्तव्य अभफ् ैमाथी रहेको हामीलाई महशुुस भएकाले आर्थिक वर्ष २०७८/०७९
						बाट नीति तथा कार्यत्रफ्म र बजेट मापफर््त उद्देश्य तय गरि संस्थाको
						चौतपिफर्् विकास गर्ने उद्देश्य राखिएको थियो । यसलाई निरत्त दियका छौं
						। सहकारि क्षेत्रमा भयको तिव्र प्रतिसप्रधा मध्यनजर गदै संस्थालाई थप
						सबल सक्षम दिगो र प्रबिधियत्तफ् बनाउनकालागि यसै आ व २०७९/०८० देखि
						अन्नलाइ माध्यमबाट सेवा दिनकोलागि मोवाईल बैंकिङ्ढ र टेब्लेट
						बैंकिङ्ढको सुरुवात गरि संस्थाको कारोबारलाई थप विश्वनिय बनाउन लागि
						परेका छौं ।
					</p>
				</article>
			</div>
		</section>
	);
};

export default Profile;
