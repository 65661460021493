import React from "react";
import About from "../components/about";
import Contact from "../components/contact";
import EMICalculator from "../components/emiCalculator/emiCalculator";
import Events from "../components/events";
import Messages from "../components/messages";
import MissionVision from "../components/missionvision";
import Partners from "../components/partners";
import Main from "./../components/main";
import Popup from "./../components/popup";
import Services from "./../components/services";

const Home = () => {
	return (
		<div className='home'>
			<Popup />

			<Main />
			<About />
			<Messages />
			<MissionVision />
			<Services />
			<EMICalculator />
			<Partners />
			<Events />
			<Contact />
		</div>
	);
};

export default Home;
