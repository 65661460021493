import React from "react";
import { PartnersData } from "../components/data";

const Remittance = () => {
	return (
		<section className='eventspage-wrapper'>
			<h2>Remittance</h2>
			<div className='eventspage vision'>
				<article className='vision-content'>
					<p>
						Remittance is an important factor of economic development in Nepal.
						Remittance has emerged as a backbone of Nepal’s economy in the last
						two decades. Nepal is one of the poor and least developed countries
						in the world. Agriculture is the main occupation of the economy,
						providing a livelihood for almost two thirds of the population of
						the country and its contribution for only one third of GDP (Economic
						Survey, 2073/74). Nepal is heavily dependent on remittances which
						values to 30 percent of GDP (Economic Survey, 2073/74). Also, Nepal
						is ranked number one remittance recipient country in terms of GDP in
						2009 which shows Nepalese economy is highly dependent on remittance.
					</p>
					<h3>Our Remittance Partners</h3>
					<div className='remittancepartners'>
						{PartnersData.map((item) => {
							return (
								<article key={item.id}>
									<img src={item.img} alt='partner' />
								</article>
							);
						})}
					</div>
				</article>
			</div>
		</section>
	);
};

export default Remittance;
