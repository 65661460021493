import React from "react";

const Deposit = () => {
	return (
		<section className='deposit-wrapper'>
			<h2>Deposit</h2>
			<div className='eventspage'>
				<div className='child-saving'>
					<h3> Child Saving</h3>

					<div className='content'>
						<ul>
							<li>Free account opening.</li>
							<li>Eligible to open account for age under 16.</li>
							<li>Must deposit Rs.100 daily and Rs.2500 monthly.</li>
							<li>Best depositor will be awarded in annual function.</li>
							<li>Cake will be provided to the despositor birthday.</li>
						</ul>
					</div>

					<table style={{ maxWidth: "500px" }}>
						<tr>
							<th style={{ textAlign: "center" }}>Duration</th>
							<th style={{ textAlign: "center" }}>Interest</th>
						</tr>

						<tr>
							<td> 1 year</td>
							<td>10% daily</td>
						</tr>
						<tr>
							<td>2 year</td>
							<td>11% daily</td>
						</tr>
						<tr>
							<td>3 year</td>
							<td>12% daily</td>
						</tr>
					</table>
				</div>
				<div className='child-saving'>
					<h3> Kramik Saving</h3>

					<div className='content'>
						<ul>
							<li>Free account opening.</li>
							<li>Daily minimum deposit should be Rs.50 and above.</li>
							<li>Evaluated every 3/3 months</li>
							<li>Best depositor will be awarded in annual function.</li>
							<li>
								After the completion of time period, interest and principle will
								be provided in total.
							</li>
						</ul>
					</div>

					<table style={{ maxWidth: "500px" }}>
						<tr>
							<th style={{ textAlign: "center" }}>Duration</th>
							<th style={{ textAlign: "center" }}>Interest</th>
						</tr>

						<tr>
							<td> 3 months</td>
							<td>9% daily interest</td>
						</tr>
						<tr>
							<td>6 months</td>
							<td>10% daily interest</td>
						</tr>
						<tr>
							<td>1 year</td>
							<td>11% daily interest</td>
						</tr>
					</table>
				</div>

				<div className='child-saving'>
					<h3>365 Installments Saving Plans</h3>

					<div className='content'>
						<ul>
							<li>
								Daily saving amount installments will be provided in total.
							</li>
							<li>
								There should be 365 installments under this plan. Deposit is not
								allowed in pulic holidays.
							</li>
							<li>Daily deposit should be done under this plan.</li>
							<li>
								After the completion of time period, interest and principle will
								be provided in total.
							</li>
						</ul>
					</div>

					<table style={{ maxWidth: "500px" }}>
						<tr>
							<th style={{ textAlign: "center" }}>Installment Amount</th>
							<th style={{ textAlign: "center" }}>Installments</th>
							<th style={{ textAlign: "center" }}>Total</th>
						</tr>

						<tr>
							<td>Rs.125</td>
							<td>365</td>
							<td>Rs.5000</td>
						</tr>
						<tr>
							<td>Rs.255</td>
							<td>365</td>
							<td>Rs.100000</td>
						</tr>
						<tr>
							<td>Rs.515</td>
							<td>365</td>
							<td>Rs.200000</td>
						</tr>
						<tr>
							<td>Rs.1020</td>
							<td>365</td>
							<td>Rs.400000</td>
						</tr>
						<tr>
							<td>Rs.2060</td>
							<td>365</td>
							<td>Rs.800000</td>
						</tr>
						<tr>
							<td>Rs.2550</td>
							<td>365</td>
							<td>Rs.1000000</td>
						</tr>
					</table>
				</div>
			</div>
		</section>
	);
};

export default Deposit;
