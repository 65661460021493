import React, { useState, useEffect } from "react";
import { RiDoubleQuotesR, RiDoubleQuotesL } from "react-icons/ri";
// import { MdOutlineMiscellaneousServices } from "react-icons/md";
import { EventsData } from "./data";
import { BsCalendar2Check } from "react-icons/bs";
import { AiOutlineBars } from "react-icons/ai";
import { Link } from "react-router-dom";

const Events = () => {
	const [eventsData, setEventsData] = useState(EventsData);
	const getEvents = async () => {
		const res = await fetch("http://localhost:8000/api/events/");
		const data = await res.json();
		return data;
	};

	useEffect(() => {
		getEvents()
			.then((data) => {
				setEventsData(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	const newEvents = eventsData.slice(eventsData.length - 4, eventsData.length);
	return (
		<div className='events-wrapper'>
			<div className='events'>
				<h2>EVENTS</h2>
				<p className='slogan'>
					<RiDoubleQuotesL /> सवल, व्यवसायिक र गुणस्तरिय सेवाकोलागि चम्पावति
					साकोस ।
					<RiDoubleQuotesR />
				</p>
				<div className='collect'>
					<div className='underline'>
						<BsCalendar2Check className='welcomeicon' />
					</div>
					<hr />
				</div>

				<div className='event'>
					{newEvents.map((item) => {
						if (!item.outside_url) {
							return (
								<article key={item.id}>
									<img src={item.image} alt='no_image' />
									<h4>{item.title}</h4>
									<span className='date'>{item.date}</span>
									<p>{`${item.description.substring(0, 80)}...`}</p>

									<button className='btn btn-success'>
										<Link to={`/singleevent/${item.id}`}>
											<AiOutlineBars className='icon' />
											KNOW MORE
										</Link>
									</button>
								</article>
							);
						}
					})}
				</div>
			</div>
		</div>
	);
};

export default Events;
