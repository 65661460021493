import React from "react";

const Iframe = () => {
  return (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3531.3948381579617!2d85.3043637150627!3d27.735965182780102!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjfCsDQ0JzA5LjUiTiA4NcKwMTgnMjMuNiJF!5e0!3m2!1sen!2snp!4v1688890799432!5m2!1sen!2snp"
      title="map"
      width="100%"
      height="450"
      style={{ border: 0 }}
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    ></iframe>
  );
};

export default Iframe;
