import React, { useState, useEffect } from "react";
import { getCurrentDate } from "../components/getCurrentDate";
const Reports = () => {
	const [reportsData, setReportsData] = useState([
		{
			id: "1",
			title: "annual meeting report",
			date: "2022-06-06",
			file: "file is loading",
		},
	]);
	const currentDate = getCurrentDate();
	const reportLength = reportsData.length;
	const getReports = async () => {
		const res = await fetch("https://champawotisaccos.com.np/api/reports/");
		const data = await res.json();
		return data;
	};
	useEffect(() => {
		getReports()
			.then((data) => {
				setReportsData(data.reverse());
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<section className='eventspage-wrapper'>
			<h2>Reports</h2>
			<div className='eventspage'>
				<ul>
					<table>
						<thead>
							<tr>
								<th>S.N</th>
								<th>Title</th>
								<th>Published Date</th>
								<th>Download</th>
							</tr>
						</thead>
						<tbody>
							{reportsData &&
								reportsData.map((report) => {
									const reportDate = report.date
										.replace("-", "")
										.replace("-", "")
										.substring(0, 8);
									return (
										<tr
											key={report.id}
											className={`${
												Number(reportDate) + 2 > Number(currentDate) &&
												"blink_me"
											}`}>
											<td>{Number(reportLength) - Number(report.id) + 1}</td>
											<td className='blink_title'>
												{report.title}
												{Number(reportDate) + 2 > Number(currentDate) && (
													<h1 className='new'>NEW</h1>
												)}
											</td>
											<td>{report.date.substring(0, 10)}</td>
											<td>
												<a href={report.file} download>
													download
												</a>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</ul>
			</div>
		</section>
	);
};

export default Reports;
