export function openClose() {
	// Date time variables
	var dtNow; // Now
	var dtOpenWD; // Weekday - Open
	var dtCloseWD; // Weekday - Close
	var dtOpenSat; // Saturday - Open
	var dtCloseSat; // Saturday - Close
	//var dtOpenSun; // Sunday - Open
	//var dtCloseSun; // Sunday - Close
	var isOpen; // is open?

	// Initialize date values
	dtNow = new Date();
	dtOpenWD = new Date(
		dtNow.getFullYear(),
		dtNow.getMonth(),
		dtNow.getDate(),
		10
	);
	dtCloseWD = new Date(
		dtNow.getFullYear(),
		dtNow.getMonth(),
		dtNow.getDate(),
		17
	);
	dtOpenSat = new Date(
		dtNow.getFullYear(),
		dtNow.getMonth(),
		dtNow.getDate(),
		10
	);
	dtCloseSat = new Date(
		dtNow.getFullYear(),
		dtNow.getMonth(),
		dtNow.getDate(),
		10,
		1
	);
	// dtOpenSun = new Date(
	// 	dtNow.getFullYear(),
	// 	dtNow.getMonth(),
	// 	dtNow.getDate(),
	// 	10
	// );
	// dtCloseSun = new Date(
	// 	dtNow.getFullYear(),
	// 	dtNow.getMonth(),
	// 	dtNow.getDate(),
	// 	16
	// );
	// Determine day and calculate isOpen
	switch (dtNow.getDay()) {
		// case 0:
		// 	isOpen =
		// 		dtNow.getTime() > dtOpenSun.getTime() &&
		// 		dtNow.getTime() < dtCloseSun.getTime();
		// 	break;

		case 6:
			isOpen =
				dtNow.getTime() > dtOpenSat.getTime() &&
				dtNow.getTime() < dtCloseSat.getTime();
			break;

		default:
			isOpen =
				dtNow.getTime() > dtOpenWD.getTime() &&
				dtNow.getTime() < dtCloseWD.getTime();
	}

	return isOpen;
}
