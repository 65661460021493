import React from "react";

const ManagementTeam = () => {
	return (
		<div>
			<h1>Management Team Here</h1>
		</div>
	);
};

export default ManagementTeam;
