import React, { useState, useEffect } from "react";
import { getCurrentDate } from "../components/getCurrentDate";
const Notice = () => {
	const currentDate = getCurrentDate();
	const [noticeData, setNoticeData] = useState([
		{
			id: "1",
			title: "annual meeting notice",
			date: "2022-06-06",
			file: "file is loading",
		},
	]);
	const noticeLength = noticeData.length;
	const getReports = async () => {
		const res = await fetch("https://champawotisaccos.com.np/api/notices/");
		const data = await res.json();
		return data;
	};
	useEffect(() => {
		getReports()
			.then((data) => {
				setNoticeData(data.reverse());
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<section className='eventspage-wrapper'>
			<h2>Notices</h2>
			<div className='eventspage'>
				<ul>
					<table>
						<thead>
							<tr>
								<th>S.N</th>
								<th>Title</th>
								<th>Published Date</th>
								<th>Download</th>
							</tr>
						</thead>
						<tbody>
							{noticeData &&
								noticeData.map((notice) => {
									const noticeDate = notice.date
										.replace("-", "")
										.replace("-", "")
										.substring(0, 8);
									return (
										<tr
											key={notice.id}
											className={`${
												Number(noticeDate) + 2 > Number(currentDate) &&
												"blink_me"
											}`}>
											<td>{Number(noticeLength) - Number(notice.id) + 1}</td>
											<td className='blink_title'>
												{notice.title}{" "}
												{Number(noticeDate) + 2 > Number(currentDate) && (
													<h1 className='new'>NEW</h1>
												)}
											</td>
											<td>{notice.date.substring(0, 10)}</td>
											<td>
												<a href={notice.file} download>
													download
												</a>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</ul>
			</div>
		</section>
	);
};

export default Notice;
