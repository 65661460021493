import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";

const Popup = React.memo(() => {
	const [showPopup, setShowPopup] = useState(true);
	const [popupNotice, setPopupNotice] = useState(null);

	const getPopups = async () => {
		const res = await fetch("https://champawotisaccos.com.np/api/popup/");

		const data = await res.json();
		return data;
	};
	useEffect(() => {
		getPopups()
			.then((data) => {
				setPopupNotice(data.reverse());
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		window.addEventListener("load", () => {
			return setShowPopup(true);
		});
	}, []);

	const closePopup = () => {
		setShowPopup(false);
	};
	if (showPopup) {
		return (
			<div className='popup'>
				<div className='popup_content'>
					<span className='close' onClick={() => closePopup()}>
						<FaTimes />
					</span>
					<div className='message'>
						{popupNotice &&
							popupNotice.map((notice) => {
								return (
									<article key={notice.id}>
										<hr />
										<p>{notice.text && notice.text}</p>;
										<br />
										<br />
										<img src={notice.image && notice.image} alt='noticeimage' />
									</article>
								);
							})}
					</div>
				</div>
			</div>
		);
	} else {
		return null;
	}
});

export default Popup;
