import React from "react";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
const Mission = () => {
	return (
		<section className='eventspage-wrapper'>
			<h2>Mission</h2>
			<div className='eventspage vision'>
				<article className='vision-content'>
					<p>
						<FaQuoteLeft className='icon' /> अन्तराष्टिय सहकारी सिद्धान्तको
						पालना गर्दै आधुनिक प्रविधि प्रयोग को गर्दैं नविनतम सदस्यहरुलाई बचत
						तथा ऋण परिचालनको माध्यमबाट सदस्यहरुको आवश्यकता पहिचान गरि उच्चतम
						प्रविधिको प्रयोग मापफर््त सदस्यको जीवनस्तरमा सुधार ल्याई सबैको
						रोजाइको साकोस बनाउने ।
						<FaQuoteRight className='icon' />
					</p>
				</article>
			</div>
		</section>
	);
};

export default Mission;
