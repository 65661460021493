import React from "react";
import { GiProgression } from "react-icons/gi";
import { AiOutlineBars } from "react-icons/ai";
import { AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
const MissionVision = () => {
	return (
		<article className='wrapper'>
			<div className='missionvision'>
				<div className='mission_vision'>
					<AiFillEye className='icon_' />
					<h2>VISION</h2>
					<p>“सवल, व्यवसायिक र गुणस्तरिय सेवाकोलागि चम्पावति साकोस ।”</p>
					<button className=''>
						<Link to='/vision'>
							<AiOutlineBars className='icon' />
							KNOW MORE
						</Link>
					</button>
				</div>
				<div className='mission_vision'>
					<GiProgression className='icon_' />
					<h2>MISSION</h2>
					<p>
						"अन्तराष्टिय सहकारी सिद्धान्तको पालना गर्दै आधुनिक प्रविधि प्रयोग
						...{" "}
						{/* को
						गर्दैं नविनतम सदस्यहरुलाई बचत तथा ऋण परिचालनको माध्यमबाट सदस्यहरुको
						आवश्यकता पहिचान गरि उच्चतम प्रविधिको प्रयोग मापफर््त सदस्यको
						जीवनस्तरमा सुधार ल्याई सबैको रोजाइको साकोस बनाउने ।" */}
					</p>
					<button className='btn btn-success'>
						<Link to='/mission'>
							<AiOutlineBars className='icon' />
							KNOW MORE
						</Link>
					</button>
				</div>
			</div>
		</article>
	);
};

export default MissionVision;
