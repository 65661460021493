import React from "react";
import { FaSearchLocation } from "react-icons/fa";
import { SiGmail } from "react-icons/si";
import { MdContactPhone } from "react-icons/md";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.png";
const Footer = () => {
  return (
    <>
      <section className="footer-wrapper">
        <div className="footer">
          <div className="info">
            <img src={logo} alt="logo" />
            <h3 className="title">
              Champawati Saving & Credit Co-opeartive Ltd.
            </h3>
            <p className="location">
              <FaSearchLocation />
              Kathmandu 16, Machhapokhari
            </p>
            <p className="contact">
              <MdContactPhone />
              <span>01-5906224, 01-5916224</span>
            </p>
            <p className="gmail">
              <SiGmail />
              champawatisaving@gmail.com{" "}
            </p>
          </div>
          <div className="aboutus">
            <h2 className="title">ABOUT US</h2>
            <ul>
              <li>
                <Link to="/home">Profile</Link>
                <Link to="/home">Vision</Link>
                <Link to="/home">Mission</Link>
                <Link to="/home">Objective</Link>
                <Link to="/home">Board Members</Link>
                <Link to="/home">Management Team</Link>
              </li>
            </ul>
          </div>
          <div className="downloads">
            <h2 className="title">DOWNLOADS</h2>
            <ul>
              <li>
                <Link to="/home">KYC Form</Link>
                <Link to="/home">New Account Form</Link>
                <Link to="/home">SMS Banking Form</Link>
                <Link to="/home">Mobile Banking Form</Link>
                <Link to="/home">E-Banking Form</Link>
              </li>
            </ul>
          </div>
          <div className="service">
            <h2 className="title">SERVICES</h2>
            <ul>
              <li>
                <Link to="/home">Remitance</Link>
                <Link to="/home">Deposit</Link>
                <Link to="/home">Loan</Link>
                <Link to="/home">SMS Banking</Link>
                <Link to="/home">Mobile Banking</Link>
                <Link to="/home">E-Banking Form</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="copyright">
        <p>
          {" "}
          Copyright &copy; 2018-2023 | Champawati Saving and Credit Co-operative
          ltd. | All Rights Reserved.
        </p>
        <span>Designed and Developed by : Milan Khati | 9863456464</span>
      </div>
    </>
  );
};

export default Footer;
