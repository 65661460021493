import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { EventsData } from "./data";
const News = () => {
	const [eventsData, setEventsData] = useState(EventsData);
	const getEvents = async () => {
		const res = await fetch("https://champawotisaccos.com.np/api/events/");

		const data = await res.json();

		return data;
	};
	useEffect(() => {
		getEvents()
			.then((data) => {
				setEventsData(data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	return (
		<div className='news'>
			<h2>Latest News</h2>

			<ul className='wrapper'>
				<div className='news_title'>
					{eventsData &&
						eventsData.map((event) => {
							if (event.outside_url) {
								return (
									<li key={event.id}>
										<a href={event.outside_url} target='blank'>
											{event.title}
										</a>
									</li>
								);
							} else {
								return (
									<li key={event.id}>
										<Link to={`/singleevent/${event.id}`}>{event.title}</Link>
									</li>
								);
							}
						})}
				</div>
			</ul>
		</div>
	);
};

export default News;
