import { AiFillHome, AiFillNotification } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { RiContactsBookUploadFill } from "react-icons/ri";
import { GiProgression } from "react-icons/gi";
import { MdOutlineMiscellaneousServices, MdEmojiEvents } from "react-icons/md";
import { AiFillCaretDown } from "react-icons/ai";
import { GiReceiveMoney, GiTakeMyMoney } from "react-icons/gi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import img1 from "../assets/images/img-1.png";
import img2 from "../assets/images/img-2.png";
import img3 from "../assets/images/img-3.png";
import img4 from "../assets/images/img-4.png";
import global from "../assets/images/global.png";
import himal from "../assets/images/himal.png";
import prabhu from "../assets/images/prabhu.png";
import western from "../assets/images/western.png";

import kym from "../assets/downloadable/KYMForm.pdf";
import newaccount from "../assets/downloadable/new account form.pdf";
export const Files = [
	{ id: 1, file: kym },
	{ id: 2, file: newaccount },
];

export const Data = [
	{
		id: 1,
		link: "/",
		icon: <AiFillHome />,
		text: "HOME",
	},
	{
		id: 2,
		link: "/about",
		icon: <FaUsers />,
		icon1: <AiFillCaretDown />,
		text: "ABOUT US",
		links: [
			{ label: "Our Profile", url: "/profile" },
			{ label: "Vision", url: "/vision" },
			{ label: "Mission", url: "/mission" },
			{ label: "Objective", url: "/objective" },
			{ label: "Board Members", url: "/boardmembers" },
			// { label: "Management Team", url: "/managementteam" },
			// { label: "Organisational Structure", url: "/organisationalstructure" },
		],
	},
	{
		id: 3,
		link: "/services",
		icon: <MdOutlineMiscellaneousServices />,
		icon1: <AiFillCaretDown />,
		text: "SERVICES",
		links: [
			{ label: "Remitance", url: "/remittance" },
			{ label: "Deposit", url: "/deposit" },
			{ label: "Loan", url: "/loan" },
			{ label: "SMS Banking", url: "/smsbanking" },
			{ label: "Mobile Banking", url: "/mobilebanking" },
		],
	},
	{
		id: 4,
		link: "/notices",
		icon: <AiFillNotification />,
		text: "NOTICES",
	},
	{
		id: 5,
		link: "/events",
		icon: <MdEmojiEvents />,
		text: "EVENTS",
	},
	{
		id: 6,
		link: "/reports",
		icon: <GiProgression />,
		text: "REPORTS",
	},
	{
		id: 7,
		link: "/contact",
		icon: <RiContactsBookUploadFill />,
		text: "CONTACT",
	},
];

export const Download = [
	{
		link: "url",
		text: "KYC Form",
	},
	{
		link: "url",
		text: "New Account Form",
	},
	{
		link: "url",
		text: "Mobile Banking Form",
	},
	{
		link: "url",
		text: "E-Banking Form",
	},
];

export const SliderImage = [
	{
		id: 1,
		img: img1,
	},
	{
		id: 2,
		img: img2,
	},
	{
		id: 3,
		img: img3,
	},
	{
		id: 4,
		img: img4,
	},
];

export const ServicesData = [
	{
		img: <GiTakeMyMoney />,
		url: "/loan",
		title: "LOAN",
		description:
			"A loan is a sum of money that one or more individuals or companies borrow from banks or other financial institutions so as to financially manage planned or unplanned events. In doing so, the borrower incurs a debt, which he has to pay back with interest and within a given period of time.",
	},
	{
		img: <GiReceiveMoney />,
		title: "DEPOSIT",
		url: "/deposit",
		description:
			" Savings is the amount of money left over after spending and other obligations are deducted from earnings. Savings represent money that is otherwise idle and not being put at risk with investments or spent on consumption.",
	},
	{
		img: <FaRegMoneyBillAlt />,
		title: "REMITTANCE",
		url: "/remittance",
		description:
			"A remittance is money that is sent from one party to another. Broadly speaking, any payment of an invoice or a bill can be called a remittance. However, the term is most often used nowadays to describe a sum of money sent by someone working abroad to their family back home.",
	},
];

export const PartnersData = [
	{
		id: 1,
		img: himal,
	},

	{
		id: 2,
		img: global,
	},

	{
		id: 3,
		img: western,
	},

	{
		id: 4,
		img: prabhu,
	},
];

export const EventsData = [
	{
		id: 1,
		title: "General meeting agm",
		date: "date",
		description:
			" doloremque a modi iusto mollitia voluptatum consequuntur! Vitae tenetur commodi adipisci magni ullam molestias veniam quo dolore repellendu",
		gallery: [img1, img2],
	},
	{
		id: 2,
		title: "Bonus distribution held 2013",
		date: "date",
		description:
			" doloremque a modi iusto mollitia voluptatum consequuntur! Vitae tenetur commodi adipisci magni ullam molestias veniam quo dolore repellendu",
		gallery: [img1, img2],
	},
	{
		id: 3,
		title: "Anuual adhibasen completed",
		date: "date",
		description:
			" doloremque a modi iusto mollitia voluptatum consequuntur! Vitae tenetur commodi adipisci magni ullam molestias veniam quo dolore repellendu",
		gallery: [img1, img2],
	},
	{
		id: 4,
		title: "profit of the company increases",
		date: "date",
		description:
			" doloremque a modi iusto mollitia voluptatum consequuntur! Vitae tenetur commodi adipisci magni ullam molestias veniam quo dolore repellendu",
		gallery: [img1, img2],
	},
];
