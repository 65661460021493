import "./App.css";
import "./assets/css/index.css";
import Home from "./pages/home";
import Header from "./components/header";
import Navbar from "./components/navbar";
import Footer from "./components/footer";
import EventsPage from "./pages/eventsPage";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SingleEvents from "./pages/singleEvents";
import Reports from "./pages/reports";
import Notice from "./pages/notice";
import Profile from "./pages/profile";
import Vision from "./pages/vision";
import Mission from "./pages/mission";
import Objective from "./pages/objective";
import BoardMembers from "./pages/boardmembers";
import Deposit from "./pages/deposit";
import Loan from "./pages/loan";
import Remittance from "./pages/remittance";
import SMSBanking from "./pages/smsBanking";
import MobileBanking from "./pages/mobileBanking";
import OrganisationalStructure from "./pages/organisationalStructure";
import ManagementTeam from "./pages/managementTeam";
import Contact from "./components/contact";
import About from "./components/about";
import Services from "./components/services";

function App() {
	return (
		<div className='App'>
			<Router>
				<Header />
				<Navbar />
				<Routes>
					<Route exact path='/' element={<Home />} />

					<Route path='/events' element={<EventsPage />} />
					<Route path='/singleevent/:id' element={<SingleEvents />} />
					<Route path='/reports/' element={<Reports />} />
					<Route path='/notices/' element={<Notice />} />
					<Route path='/profile/' element={<Profile />} />
					<Route path='/vision/' element={<Vision />} />
					<Route path='/mission/' element={<Mission />} />
					<Route path='/objective/' element={<Objective />} />
					<Route path='/boardmembers/' element={<BoardMembers />} />
					<Route path='/deposit/' element={<Deposit />} />
					<Route path='/loan/' element={<Loan />} />
					<Route path='/remittance/' element={<Remittance />} />
					<Route path='/mobilebanking/' element={<SMSBanking />} />
					<Route path='/smsbanking/' element={<MobileBanking />} />
					<Route path='/about' element={<About />} />
					<Route path='/services' element={<Services />} />
					<Route
						path='/organisationalstructure/'
						element={<OrganisationalStructure />}
					/>
					<Route path='/managementteam/' element={<ManagementTeam />} />
					<Route path='/contact/' element={<Contact />} />

					<Route
						path='*'
						element={
							<h1>
								Wrong url <a href='/'>back to home</a>
							</h1>
						}
					/>
				</Routes>
				<Footer />
			</Router>
		</div>
	);
}

export default App;
