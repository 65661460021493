import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/images/img-1.png";
import { RiDoubleQuotesR, RiDoubleQuotesL } from "react-icons/ri";
import { GiGreekTemple } from "react-icons/gi";
import { AiOutlineBars } from "react-icons/ai";

const About = () => {
	return (
		<div className='about'>
			<h2>welcome to champawati</h2>
			<p className='slogan'>
				<RiDoubleQuotesL /> सवल, व्यवसायिक र गुणस्तरिय सेवाकोलागि चम्पावति साकोस
				।
				<RiDoubleQuotesR />
			</p>
			<div className='collect'>
				<div className='underline'>
					<GiGreekTemple className='welcomeicon' />
				</div>
				<hr />
			</div>

			<div className='content'>
				<div className='content_text'>
					<p>
						<strong>Champwati </strong>is the well known Saving and Credit
						Co-operative existed in the field since 2067/68 BS. Registration
						number is 3387/067/068. It has won the trust of thousands of happy
						members.
					</p>
					<p>
						We are the best in generating the wealth and keep your money safe.
						We focus on giving the best services to the members. We provide the
						most flexible loan for our members. We are well equiped with all the
						services that a standard saving and credit company should pose and
						above the standards. We have <strong>SMS Banking</strong>,{" "}
						<strong>Mobile Banking</strong>, <strong>QR Code</strong> and we
						have this digital medium of sharing information for our users. Our
						members will be up to date without physically visiting the office.
						We share every events and notices through our office website
						champawotisaccos.com.np. Thank you !
					</p>
					<button className='btn btn-success'>
						<Link to='/profile'>
							<AiOutlineBars className='icon' />
							KNOW MORE
						</Link>
					</button>
				</div>
				<img src={img1} alt='org_image' />
			</div>
		</div>
	);
};

export default About;
