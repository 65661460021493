import React from "react";

const Loan = () => {
	return (
		<section className='deposit-wrapper'>
			<h2>Loan</h2>
			<div className='eventspage'>
				<div className='child-saving'>
					<p style={{ textAlign: "left", margin: "1.5rem auto" }}>
						We provide the most flexible loan according to the your need. There
						are different types of loan services we provide. Please contact the
						office for further details.
					</p>
					<h3>Our Loan Schemes</h3>
					<div className='content'>
						<ul>
							<li>Business Loan</li>
							<li>Education Loan </li>
							<li>Service Loan </li>
							<li>Agriculture Loan </li>
							<li>Loan against Periodic Deposit Receipt</li>
							<li>Personal Loan </li>
							<li>Hire Purchase Loan</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Loan;
